<template>
  <div>
    <!-- 忘记密码 -->
    <div class="top">
      <div class="top-left">
         <img src="~pub/img/logo.png" alt="">
         <p class="fontsize">雲途志</p>
      </div>
      <router-link to='/login' class="tologin">登录</router-link>
    </div>
    <div class="bottom">
      <el-steps  :active="activenum" finish-status="success" class="steps">
        <el-step title="第一步" description="输入企业登录信息">11</el-step>
        <el-step title="第二步" description="输入图形验证码"></el-step>
        <el-step title="第三步" description="重置密码"></el-step>
        <el-step title="第四步" description="修改成功"></el-step>
      </el-steps>
    </div>
    <!-- 0 -->
    <div v-show="activenum==0">
      <el-form :model="ruleForm" status-icon  ref="ruleForm" label-width="100px" class="demo-ruleForm formclass" >
        <el-form-item label="登录账号" prop="account" style="width:80%" :error="errorMsg0" :rules="[{ required: true, message: '登录账号不能为空'}]">
          <el-input  v-model="ruleForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item  class="firststy" style=" margin-left: 22%;">
          <el-button type="primary" @click="submitForm('ruleForm')">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 1 -->
    <div v-show="activenum==1">
      <el-form :model="ruleForm1" status-icon  ref="ruleForm1" label-width="150px" class="demo-ruleForm formclass" >
         <el-form-item label="绑定手机号">
          {{phone}}
        </el-form-item>
        <el-form-item label="图形验证码" prop="captcha" :error="errorMsg" :rules="[{ required: true, message: '图形验证码不能为空'}]">
          <el-input  v-model="ruleForm1.captcha" autocomplete="off" class="verification" style="float:left"></el-input>
          <img  :src="captchaPath" alt=""  @click="getCaptcha()" srcset="" class="imgsrc">
        </el-form-item>
        <el-form-item label="短信验证码" prop="vcode" :error="errorMsg1" :rules="[{ required: true, message: '短信验证码不能为空'}]">
          <el-input  v-model="ruleForm1.vcode" autocomplete="off" class="verification"></el-input>
          <el-button type="primary" @click="sendSms()" style="margin-left: 6px;" :disabled="btnBool">{{yzmtext}}</el-button>
        </el-form-item>
        <el-form-item  class="firststy">
          <el-button type="primary" @click="submitForm1('ruleForm1')">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
     <!-- 2 -->
    <div v-show="activenum==2">
      <el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm formclass">
        <el-form-item label="输入新密码" prop="pass" style="width:80%">
          <el-input type="password" v-model="ruleForm2.pass" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkPass" style="width:80%">
          <el-input type="password" v-model="ruleForm2.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="firststy"  style=" margin-left: 20%;">
          <el-button type="primary" @click="submitForm2('ruleForm2')">重置密码</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 3 -->
    <div v-if="activenum==3">
      <i class="el-icon-success success"></i>
      <h5>恭喜您！修改密码成功！即将前往登录页 ...</h5>
    </div>
  </div>
</template>

<script>
import { getUUID } from "@/utils/index.js";
import {resetpw,checkCode,userInfoAccount,newpPssword} from  "@/api/index";
export default {
  data() {
      // 密码设置
       var validatePass1 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm2.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm2.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      activenum:0,
      phone:"18406575055",
      errorMsg0:null,
      errorMsg:null,
      errorMsg1:null,
      captchaPath: "",//验证码图片
      btnBool:false,//获取验证码禁用
      yzmtext:"发送验证码",
      ruleForm: {
          account: '',
      },
      partyId:"",
      ruleForm1: {
          uuid:'',
          captcha:'',
          vcode:''
      },
      ruleForm2: {
          pass: '',
          checkPass: '',
        },
       rules2: {
          pass: [
            { validator: validatePass1, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ]
        }
    };
  },
  created(){
    this.getCaptcha();//获取验证码
  },
  methods:{
    
    // 验证登录账号
    submitForm() {
         this.$nextTick(() => {this.errorMsg0 =null}) 
         if (this.ruleForm.account=="") {
           this.$nextTick(() => {this.errorMsg0 ="登录账号不能为空"}) 
           return
         }
         userInfoAccount({
           account:this.ruleForm.account
         }).then((data)=>{
            if (data.code==200) {
              this.phone=data.data.mobile
              if (data.data.partyId) {
                this.partyId=data.data.partyId
              }
              this.activenum=1
            }else{
               this.$nextTick(() => {this.errorMsg0 =data.msg}) 
            }
          })
    },
    // 校验短信
    submitForm1(formName) {
       this.$nextTick(() => {this.errorMsg1 =null})
        this.$refs[formName].validate((valid) => {
          if (valid) {
             checkCode({
              mobile:this.phone,
              vcode:this.ruleForm1.vcode
            }).then((data=>{
              if (data.code==200) this.activenum=2
               this.$nextTick(() => {this.errorMsg1 =data.msg})
            }))
          } else {
            return false;
          }
        });
    },
    // 校验新密码
    submitForm2(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              newpPssword({
              newPassword:this.ruleForm2.pass,
              mobile:this.phone,
              vcode:this.ruleForm1.vcode,
              partyId:this.partyId
            }).then((data=>{
               if (data.code==200) {
                   this.activenum=3
                  setTimeout(()=>{
                    this.$router.push("login");
                  },5000)
                }
            }))
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
        // 获取验证码
    getCaptcha() {
      this.ruleForm1.uuid = getUUID();
      let baseURL = process.env.VUE_APP_BASE_API; //环境变量后台URL
      if (!baseURL) baseURL = window.url;
      this.captchaPath = baseURL + `/biz/stateless/captcha.jpg?uuid=${this.ruleForm1.uuid}`;
    },
    // 发送验证
    sendSms(){
       this.$nextTick(() => {this.errorMsg =null}) 
      if (this.ruleForm1.captcha=="") {
         this.$nextTick(() => {this.errorMsg ="图形验证码不能为空"}) 
         return
      }
      resetpw({
        mobile:this.phone,
        uuid: this.ruleForm1.uuid,
        captcha: this.ruleForm1.captcha,
      }).then((data)=>{
        if (data.code==200){
            this.getyzm()
            return
          }
            this.getCaptcha();
           this.$nextTick(() => {this.errorMsg =data.msg})
      })
    },
    // 倒计时
     getyzm() {
      this.btnBool = true;
      let i = 60;
      let timer = setInterval(() => {
        this.yzmtext =  i + ' S';
        i--;
        if (i < 0) {
            this.btnBool = false;
            this.yzmtext = '发送验证码';
            clearInterval(timer);
        }
      }, 1000);
    }, 
       
  }
};
</script>

<style scoped >
  .top{
    height: 80px;
    background: #9e99b9;
  }
  .top-left{
    height: 80px;
    float: left;
    margin-left: 30%;
    overflow: hidden;
 }

  .top img{
    height: 60px;
    margin-top: 10px;
 }
 .fontsize{
    margin-left: 10px;
    font-size: 30px;
    color: #ffffff;
    font-weight: 600;
    display: inline-block;
    transform: translateY(-20px);
}
  .top .tologin{
    float: left;
    height: 80px;
    line-height: 80px;
    margin-left: 40%;
    color: aliceblue;   
  }
  .bottom{
    width: 100%;
    height: 300px;
    overflow: hidden;
  }
  .steps{
    width: 54%;
    height: 100px;
    margin:140px auto;
  }
  .formclass{
    width:30%;
    margin: auto;
    /* background: darkcyan; */
  }
  .firststy{
     margin-top:70px;
     margin-left: 15%;
  }
  .verification{
    width: 30%;
  }
  .success{
    width: 100%;
    height: 100px;
    text-align: center;
    color: #09bb07;
    font-size: 80px;
  }
  h5{
    text-align: center;
    color: #696a6b;
  }
   .verification{
    width: 30%;
  }
    .imgsrc{
    float: left;
    height: 40px;
    width:130px;
    margin-left: 6px;
  }
</style>

